<template>
    <div>
        Your Payment Information
    </div>
</template>

<script>
export default {  
  
}
</script>
 